<template>
  <layout>
    <template slot="content">
      <div>
        <div class="page-header mb-3 mb-md-2">
          <h3 class="page-title"> Usuarios </h3>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Usuarios</a></li>
              <li class="breadcrumb-item active" aria-current="page">Todos los usuarios</li>
            </ol>
          </nav>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row justify-content-between align-items-center mb-4">
                  <div class="col-auto ml-auto">
                    <button class="btn btn-sm btn-gradient-info" @click.prevent="openModelCreate">
                      <i class="mdi mdi-plus"></i> Nuevo
                    </button>
                  </div>
                </div>
                <datatable :api="apiEndPoint" :fields="fields"></datatable>
              </div>
            </div>
          </div>
        </div>
        <user-create></user-create>
        <user-update :payload="userSelected"></user-update>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/BaseLayout'
import Datatable from '@/components/TableLayout'
import UserCreate from '@/components/CreateUser'
import UserUpdate from '@/components/EditUser'
import { mapMutations } from 'vuex'

export default {
  name: 'User',
  data () {
    return {
      apiEndPoint: 'api/users',
      fields: [
        {
          name: 'id',
          title: '#',
          showOnVisibilityMenu: true
        },
        {
          name: 'full_name',
          title: 'Nombre',
          showOnVisibilityMenu: false
        },
        {
          name: 'email',
          title: 'Email',
          sortField: 'email',
          formatter (value) {
            return value.toLowerCase()
          }
        },
        {
          name: 'forbidden',
          title: 'Estado',
          titleClass: 'text-center',
          dataClass: 'text-center',
          visible: false,
          formatter: (value) => {
            if (value === 0) {
              return '<a class=text-dark"><i class="mdi mdi-checkbox-marked-circle-outline"></i></a>'
            } else {
              return '<a class="text-info"><i class="mdi mdi-checkbox-marked-circle"></i></a>'
            }
          }
        }
      ],
      userSelected: {}
    }
  },
  methods: {
    ...mapMutations('User', ['TOGGLE_MODAL_CREATE', 'TOGGLE_MODAL_UPDATE']),
    openModelCreate () {
      this.TOGGLE_MODAL_CREATE()
    },
    openModelUpdate () {
      this.TOGGLE_MODAL_UPDATE()
    }
  },
  mounted () {
    this.$events.$on('table-item-edit', eventData => {
      this.userSelected = eventData
      this.openModelUpdate()
    })
  },
  beforeDestroy () {
    this.$events.off('table-item-edit')
  },
  components: {
    Layout,
    Datatable,
    UserCreate,
    UserUpdate
  }
}
</script>
