<template>
  <b-modal
    v-model="modalOpen"
    no-fade ref="create-user"
    :no-close-on-backdrop="true"
    hide-footer
    hide-header
    hide-backdrop
    size="xl"
  >
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Editar Usuario</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="exampleInputUsername1">Nombre</label>
                  <input v-model="payload.first_name" type="text" class="form-control" id="exampleInputUsername1" placeholder="Nombre">
                  <div v-if="errors.hasOwnProperty('first_name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.first_name[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="exampleInputEmail1">Apellido</label>
                  <input v-model="payload.last_name" type="text" class="form-control" id="exampleInputEmail1" placeholder="Apellido">
                  <div v-if="errors.hasOwnProperty('last_name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.last_name[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6 mb-4">
                  <label for="exampleInputPassword1">Email</label>
                  <input v-model="payload.email" type="email" class="form-control" id="exampleInputPassword1" placeholder="Email">
                  <div v-if="errors.hasOwnProperty('email')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.email[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6 mb-4">
                  <label for="exampleInputPassword1">Contraseña</label>
                  <input v-model="payload.password" type="password" class="form-control"  placeholder="Contraseña">
                  <div v-if="errors.hasOwnProperty('password')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.password[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6 mb-4">
                  <label for="exampleInputPassword1">Confirmar Contraseña</label>
                  <input v-model="payload.password_confirmation" type="password" class="form-control" placeholder="Confirmar Contraseña">
                  <div v-if="errors.hasOwnProperty('password_confirmation')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.password_confirmation[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6 mb-4">
                  <label for="exampleInputPassword1">Abogado</label>
                  <div class="row">
                    <div class="col-12">
                      <switches v-model="payload.lawyer"  theme="bootstrap" color="success" type-bold="true"></switches>
                    </div>
                  </div>
                </div>
                <div class="form-group col-lg-6 mb-4">
                  <label for="exampleInputPassword1">Deshabilitado</label>
                  <div class="row">
                    <div class="col-12">
                      <switches v-model="payload.forbidden"  theme="bootstrap" color="success" type-bold="true"></switches>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-lg-6 mb-4">
                  <label for="role_id">Roles asignados : <span class="font-weight-bold text-info font-italic"><span v-if="payload.roles.length"> {{ payload.roles[0].name | uppercase }} </span> <span v-else> Sin asignar</span></span></label>
                  <select id="role_id" v-model="payload.role_id" class="form-control">
                    <option selected>Actualizar Rol</option>
                    <option v-for="option in rolesCollection" v-bind:key="option.id" v-bind:value="option.id">
                      {{ option.name | uppercase }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-auto">
                  <button @click.prevent="updateUser" class="btn btn-gradient-info btn-md">Actualizar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import Switches from 'vue-switches'

export default {
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  name: 'UpdateUser',
  data () {
    return {
      errors: {},
      role_id: null
    }
  },
  methods: {
    ...mapActions('User', ['update', 'assignRole']),
    ...mapActions('Role', ['getAll']),
    ...mapMutations('User', ['TOGGLE_MODAL_UPDATE']),
    updateUser () {
      this.update(this.payload)
        .then(() => {
          this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reset () {
      this.first_name = null
      this.last_name = null
      this.email = null
      this.password = null
      this.password_confirmation = null
      this.errors = {}
      this.TOGGLE_MODAL_UPDATE(false)
      this.$events.fire('refresh-table')
    }
  },
  computed: {
    ...mapGetters('User', ['modalUpdate']),
    ...mapGetters('Role', ['roles']),
    modalOpen () {
      return this.modalUpdate
    },
    rolesCollection () {
      return this.roles
    }
  },
  mounted () {
    this.getAll()
  },
  components: {
    Switches
  }
}
</script>
